import React, { useEffect, useMemo, useState, createContext } from 'react';
import PropTypes from 'prop-types';
import axiosInstance from 'src/helpers/axios/axiosInstance';

import getCommonOptions from '../helpers/axios/getCommonOptions';

export const AuthContext = createContext({
  isAuthenticated: null,
  setIsAuthenticated: () => {},
  user: null,
  setUser: () => {},
  otpRequired: false,
  setOtpRequired: () => {},
});

export default function AuthContextProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [user, setUser] = useState(null);
  const [otpRequired, setOtpRequired] = useState(false); // New state for OTP requirement

  const loadAuthUser = () => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      setIsAuthenticated(false);
      return;
    }
    console.log(getCommonOptions())
    axiosInstance
      .get('/api/auth/users/me/', getCommonOptions())
      .then((res) => {
        setUser(res.data);
        setIsAuthenticated(true);
        setOtpRequired(false); // Clear OTP state on successful user load
      })
      .catch((err) => {
        if (err.response && err.response.status === 401 && err.response.data?.otp_required) {
          // Backend indicates OTP verification is required
          setOtpRequired(true);
        } else {
          setIsAuthenticated(false);
        }
      });
  };

  const providerValue = useMemo(() => {
    return {
      isAuthenticated,
      setIsAuthenticated,
      user,
      setUser,
      otpRequired,
      setOtpRequired,
    };
  }, [isAuthenticated, setIsAuthenticated, user, setUser, otpRequired, setOtpRequired]);

  useEffect(() => {
    if (!user && (isAuthenticated === null || isAuthenticated === true)) {
      loadAuthUser();
    }
  }, [user, isAuthenticated]);

  return (
    <AuthContext.Provider value={providerValue}>
      {children}
    </AuthContext.Provider>
  );
}

AuthContextProvider.propTypes = {
  children: PropTypes.node,
};
