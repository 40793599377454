import axios from 'axios';

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: '', // Your base API URL
});

// Add a request interceptor to handle token refreshing
axiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');

    if (!accessToken) {
      return config;
    }

    // Decode the token to check expiration
    try {
      const tokenPayload = JSON.parse(atob(accessToken.split('.')[1]));
      const isTokenExpired = tokenPayload.exp * 1000 < Date.now();

      if (isTokenExpired && refreshToken) {
        // Attempt to refresh the token
        const response = await axios.post('/api/auth/token/refresh/', { refresh: refreshToken });
        const newAccessToken = response.data.access;

        // Update tokens in local storage
        localStorage.setItem('accessToken', newAccessToken);
        axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;

        // Attach the refreshed token to the request
        config.headers.Authorization = `Bearer ${newAccessToken}`;
      } else {
        // Attach the existing valid token to the request
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    } catch (error) {
      console.error('Error while refreshing token:', error);

      // If refreshing fails, log the user out
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      axios.defaults.headers.common['Authorization'] = null;

      throw new Error('Session expired. Please log in again.');
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
