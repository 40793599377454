import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { Box, TextField, Button, Typography, Paper, Alert, CircularProgress } from '@mui/material';
import useRequestAuth from '../../hooks/useRequestAuth';

export default function OtpSetup() {
  const { generateOtp, qrCode, loading, verifyOtp } = useRequestAuth(); // Use the hook
  const [otpToken, setOtpToken] = useState('');
  const [verificationStatus, setVerificationStatus] = useState(null);

  useEffect(() => {
    // Generate OTP QR code when the component loads
    generateOtp();
  }, [generateOtp]);

  const handleVerifyOtp = async () => {
    try {
      await verifyOtp(otpToken); // Use the verifyOtp method from the hook
      setVerificationStatus('success');
    } catch {
      setVerificationStatus('error');
    }
  };

  return (
    <Box sx={{ padding: 0 }}>
      <Typography variant="h5" sx={{ mb: 3 }}>
        Set Up Two-Factor Authentication (TOTP)
      </Typography>
      <Paper sx={{ padding: 3, backgroundColor: 'background.paper' }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          What is Two-Factor Authentication?
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Two-Factor Authentication (2FA) adds an extra layer of security to your account.
          After entering your password, you'll also need to provide a one-time code generated
          by an authenticator app. This helps protect your account even if your password is compromised.
        </Typography>
        <Typography variant="h6" sx={{ mb: 2 }}>
          What is TOTP?
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          TOTP stands for Time-Based One-Time Password. It is a secure method for generating
          one-time codes using an authenticator app like Google Authenticator, Microsoft Authenticator, or Authy.
        </Typography>
        <Typography variant="h6" sx={{ mb: 2 }}>
          How to Use
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          1. Download an authenticator app on your smartphone. Popular choices include:
          <ul>
            <li>Google Authenticator</li>
            <li>Microsoft Authenticator</li>
            <li>Authy</li>
          </ul>
          2. Scan the QR code below using your chosen authenticator app.
          <br />
          3. Enter the generated one-time code below to verify and activate 2FA for your account.
        </Typography>

        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {qrCode ? (
              <>
                <Typography variant="h6" sx={{ mt: 2 }}>
                  Scan this QR Code
                </Typography>
                <Box sx={{ mt: 2, mb: 2 }}>
                  <QRCode value={qrCode} size={200} />
                </Box>
              </>
            ) : (
              <Alert severity="error" sx={{ mt: 2 }}>
                Unable to generate QR code. Please try again.
              </Alert>
            )}
          </>
        )}

        <Typography sx={{ mt: 2 }}>
          Enter the 6-digit code from your authenticator app below:
        </Typography>
        <TextField
          label="Enter OTP"
          variant="outlined"
          value={otpToken}
          onChange={(e) => setOtpToken(e.target.value)}
          sx={{ mt: 2, mb: 2, width: '100%' }}
        />
        <Button
          variant="contained"
          onClick={handleVerifyOtp}
          disabled={loading || !otpToken}
        >
          Verify OTP
        </Button>

        {verificationStatus === 'success' && (
          <Alert severity="success" sx={{ mt: 3 }}>
            OTP verified successfully! Your two-factor authentication is now active.
          </Alert>
        )}
        {verificationStatus === 'error' && (
          <Alert severity="error" sx={{ mt: 3 }}>
            OTP verification failed. Please try again.
          </Alert>
        )}
      </Paper>
    </Box>
  );
}
