function getCommonOptions() {
  const accessToken = localStorage.getItem('accessToken');

  if (!accessToken) {
    return {};
  }

  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
}

export default getCommonOptions;
