import { useCallback, useState, useContext } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import formatHttpApiError from '../helpers/formatHttpApiError';

import { AuthContext } from '../contexts/AuthContextProvider';
import getCommonOptions from '../helpers/axios/getCommonOptions';
import { useNavigate } from 'react-router-dom';
import axiosInstance from 'src/helpers/axios/axiosInstance';

export default function useRequestAuth() {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState(null);
  const { setIsAuthenticated, setUser } = useContext(AuthContext);
  const [logoutPending, setLogoutPending] = useState(false);
  const [qrCode, setQrCode] = useState(null); // QR code state
  const navigate = useNavigate();

  const handleRequestError = useCallback(
    (err) => {
      const formattedError = formatHttpApiError(err);
      setError(formattedError);
      enqueueSnackbar(formattedError);
      setLoading(false);
    },
    [enqueueSnackbar, setLoading, setError]
  );

  const register = useCallback(
    ({ username, email, password }, successCallback) => {
      setLoading(true);
      axios
        .post('/api/auth/users/', {
          username,
          email,
          password,
        })
        .then(() => {
          enqueueSnackbar('Sign up is successful, you can now sign in with your credentials', { variant: 'success' });
          setLoading(false);
          if (successCallback) {
            successCallback();
          }
        })
        .catch(handleRequestError);
    },
    [enqueueSnackbar, handleRequestError, setLoading]
  );

  const login = useCallback(
    async ({ username, password, otp_token }) => {
      setLoading(true);
      try {
        const res = await axios.post('/api/auth/token/login/', { username, password, otp_token });
        const { refresh, access, totp_configured } = res.data;

        localStorage.setItem('refreshToken', refresh);
        localStorage.setItem('accessToken', access);
        axios.defaults.headers.common['Authorization'] = `Bearer ${access}`;
        setIsAuthenticated(true);

        if (!totp_configured) {
          navigate('/auth/configure-totp'); // Redirect to TOTP setup
        } else {
          navigate('/auth/verify-totp'); // Redirect to dashboard or homepage
        }

        enqueueSnackbar('Login successful!', { variant: 'success' });
      } catch (err) {
        enqueueSnackbar('Login failed. Please check your credentials or OTP.', { variant: 'error' });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar, setIsAuthenticated, navigate]
  );

  const logout = useCallback(() => {
    setLogoutPending(true);
    axiosInstance
      .post('/api/auth/token/logout/', null, getCommonOptions())
      .then(() => {
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('accessToken');
        setLogoutPending(false);
        setUser(null);
        setIsAuthenticated(false);
      })
      .catch((err) => {
        setLogoutPending(false);
        handleRequestError(err);
      });
  }, [handleRequestError, setLogoutPending, setIsAuthenticated, setUser]);

  const resetPassword = useCallback(
    ({ currentPassword, newPassword, reNewPassword }) => {
      if (newPassword !== reNewPassword) {
        enqueueSnackbar('New passwords do not match', { variant: 'error' });
        return; // Stop execution if passwords do not match
      }

      setLoading(true);
      axiosInstance
        .post(
          '/api/auth/users/set_password/',
          {
            current_password: currentPassword,
            new_password: newPassword,
            re_new_password: reNewPassword,
          },
          getCommonOptions()
        )
        .then(() => {
          enqueueSnackbar('Password reset successful', { variant: 'success' });
          setLoading(false);
        })
        .catch(handleRequestError);
    },
    [handleRequestError, setLoading, enqueueSnackbar]
  );

  const generateOtp = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.post('/api/auth/mfa/generate/', null, getCommonOptions());
      setQrCode(res.data.otp_url); // Save the QR code URL
      enqueueSnackbar('QR Code generated. Scan it in your Authenticator app.', {
        variant: 'info',
      });
      setLoading(false);
    } catch (err) {
      handleRequestError(err);
    }
  }, [handleRequestError]);

  const verifyOtp = useCallback(
    async (otp_token) => {
      setLoading(true);
      try {
        const res = await axiosInstance.post(
          '/api/auth/mfa/verify/',
          { otp_token },
          getCommonOptions() // Pass access token in the headers
        );
        const { access } = res.data; // Get the new access token

        // Update the access token in local storage and headers
        localStorage.setItem('accessToken', access);
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${access}`;

        enqueueSnackbar('OTP verified successfully!', { variant: 'success' });
        return res.data; // Return any additional response data if necessary
      } catch (err) {
        enqueueSnackbar('OTP verification failed. Please try again.', { variant: 'error' });
        throw err; // Propagate the error for additional handling if necessary
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar]
  );

  return {
    register,
    login,
    loading,
    resetPassword,
    logout,
    logoutPending,
    error,
    generateOtp,
    verifyOtp,
    qrCode,
  };
}
