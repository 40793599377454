import React, { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import axiosInstance from 'src/helpers/axios/axiosInstance'
import formatHttpApiError from '../helpers/formatHttpApiError'
import getCommonOptions from '../helpers/axios/getCommonOptions'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Grid from '@mui/material/Grid'


function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

function a11yProps (index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

export default function BasicTabs (props) {
  const [value, setValue] = React.useState(0)
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const { enqueueSnackbar } = useSnackbar()
  let subControl
  let subControlTab
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  useEffect(() => {
    setIsLoading(true)
    axiosInstance.get(`/api/${props.endpoint}/${props.id}/`, getCommonOptions())
      .then((res) => {
        setData(res.data)
        setIsLoading(false)
      }).catch((err) => {
        const formattedError = formatHttpApiError(err)
        enqueueSnackbar(formattedError)
        setIsLoading(false)
      })
  }, [enqueueSnackbar, setIsLoading, props.id])

  if (isLoading && !data) {
    return <Typography>Loading...</Typography>
  }
  if (!data || data.length === 0) {
    return <Typography>Empty</Typography>
  }
  if (data?.subControls) {
    subControlTab = <Tab label="Sub controls" {...a11yProps(1)} />
    subControl = <TabPanel value={value} index={1}>
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Sub control</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {data.subControls.map(subcontrol => {
            return (
              <TableRow
                key={subcontrol.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >

                <TableCell component="th" scope="row">
                  {subcontrol.title}
                  <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Description</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            {subcontrol.description}
            </Typography>
          </AccordionDetails>
              </Accordion>
              <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Outcomes</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            {subcontrol.outcome}
            </Typography>
          </AccordionDetails>
              </Accordion>
                </TableCell>
                <TableCell component="th" scope="row">
                  {subcontrol.status}
                </TableCell>
                <TableCell component="th" scope="row">
                  {subcontrol.type}
                </TableCell>
              </TableRow>
            )
          })}
          </TableBody>
        </Table>
      </TableContainer>
        </TabPanel>
  }
  return (
    <Box sx={{ paddingLeft: 4 }}>
        <Box sx={{ width: '100%' }}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
        <Typography variant="h6">{`${isLoading ? 'Loading...' : data.title}`}</Typography>
        </Grid>
      </Grid>
    </Box>
      <Box sx={{ borderBottom: 1, borderColor: 'background.paper' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Overview" {...a11yProps(0)} />
          {subControlTab}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
      <Box sx={{ flexGrow: 1 }}>
      <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {
            Object.entries(data).map(([key, value]) => {
              return (
          <>
          <Grid item xs={4} >
          <Typography> {key} :</Typography>
          </Grid>
          <Grid item xs={8}>
          <Typography> {value ? value.toString() : 'empty'}</Typography>
          </Grid>
          </>
              )
            })}
        </Grid>
    </Box>
      </TabPanel>
      {subControl}
    </Box>
  )
}
