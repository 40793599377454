import React, { useState, useEffect } from 'react';
import CustomizedTreeView from '../../../components/General/CustomizedTreeView';
import useRequestCompliance from '../../../hooks/useRequestCompliance';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DetailedAsset from 'src/components/Asset/DetailedAssset';
import {
  Typography,
  Grid,
  Box,
  TextField,
  Paper,
  Card,
  CardContent,
  Divider,
} from '@mui/material';
import DynamicLevelSelector from 'src/components/General/DynamicLevelSelector';

// Debounce function to delay API call
const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
};

const filterData = (node, searchTerm) => {
  if (!node || !node.title) return null;

  const isSelfMatch = node.title.toLowerCase().includes(searchTerm.toLowerCase());

  const filterChildren = (childrenKey) => {
    if (!node[childrenKey]) return [];
    return node[childrenKey].map(childNode => filterData(childNode, searchTerm)).filter(Boolean);
  };

  const filteredChildren = {
    missions: filterChildren('missions'),
    related_organization: filterChildren('related_organization'),
    information_systems: filterChildren('information_systems'),
    primary_assets: filterChildren('primary_assets'),
    supporting_assets: filterChildren('supporting_assets'),
    related_assets: filterChildren('related_assets'),
    related_information_systems: filterChildren('related_information_systems'),
  };

  if (isSelfMatch) {
    return { ...node }; // If the node is a match, return it with all its original children.
  } else if (Object.values(filteredChildren).some(children => children.length > 0)) {
    return { ...node, ...filteredChildren }; // If the node has matching descendants, return it with the filtered children.
  }

  return null;
};

const ContentPage = () => {
  const [selectedNode, setSelectedNode] = useState(null);
  const [catId, setCatId] = useState();
  const [searchTerm, setSearchTerm] = useState(''); // State to capture search input
  const { getComplianceList: getAssetManagementInstances, complianceList: amInstances } = useRequestCompliance({
    endpoint: 'asset_management_tree',
    resourceLabel: 'Asset management instances',
  });
  const { getCompliance: getCatInstance, compliance: catInstance, resetCompliance } =
    useRequestCompliance({ endpoint: 'categorization_instances' });

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedEndpoint, setSelectedEndpoint] = useState('');
  const { getCompliance: getResource, compliance: resource } =
    useRequestCompliance({ endpoint: selectedEndpoint });

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  useEffect(() => {
    getAssetManagementInstances();
  }, [getAssetManagementInstances]);
  console.log(resource)
  useEffect(() => {
    if (selectedEndpoint) {
      debounce(() => getResource(selectedNode.id), 300)(); // Debounced API call
    }
  }, [selectedEndpoint, selectedNode, getResource]);

  useEffect(() => {
    if (resource) {
      resource.security_requirements
        ? setCatId(resource.security_requirements)
        : setCatId(resource.security_compatibilities);
    }
  }, [resource]);

  useEffect(() => {
    if (catId) {
      getCatInstance(catId);
    }
  }, [catId, getCatInstance]);

  const handleNodeSelect = (node) => {
    if (node !== selectedNode) {
      setSelectedNode(node);
      setSelectedEndpoint(node.url); // Update the endpoint when a node is selected
      resetCompliance(); // Reset catInstance to null
    }
  };

  const filteredData = amInstances.results?.map(node => filterData(node, searchTerm)).filter(Boolean);
  console.log(amInstances)
  return (
    <>
      <Drawer
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'block', md: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: '40%',
            marginTop: '64px',
          },
        }}
        anchor="right"
        variant="temporary"
        open={drawerOpen}
        onClose={closeDrawer}
      >
        <div>
          <IconButton onClick={closeDrawer}>
            <ChevronRightIcon />
          </IconButton>
        </div>
        <Box p={2} sx={{ flexGrow: 1 }}>
          {catInstance ? (
            <DynamicLevelSelector data={catInstance.categorization_model} initialValues={catInstance} />
          ) : null}
        </Box>
      </Drawer>

      <Grid container>
        {/* Title Section */}
        <Box p={2} sx={{ flexGrow: 1 }}>
          <Typography variant="h5">Eunoia Asset Explorer</Typography>
          <Divider />
        </Box>

        {/* Main Content */}
        <Grid container spacing={2} style={{ minHeight: 'calc(100vh - 64px)' }}>
          {/* TreeView Sidebar */}
          <Grid item xs={12} md={4}>
            <Paper
              style={{
                height: 'calc(100vh - 64px)', // adjust 64px if you have different header/footer sizes
                overflow: 'auto',
              }}
            >
              <Box p={2}>
                <TextField
                  label="Search"
                  variant="outlined"
                  size="small"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  fullWidth
                />
              </Box>
              <Box p={2}>
                <CustomizedTreeView data={filteredData} handleNodeSelect={handleNodeSelect} />
              </Box>
            </Paper>
          </Grid>
          {/* Details View */}
          <Grid item xs={12} md={8}>
            <Grid container spacing={2}>
              {/* Resource Details */}
              {selectedNode ? (
                <>
                  {/* If an element is selected, render the detailed view */}
                  {resource ? (
                    <>
                      <Grid item xs={12}>
                        <DetailedAsset id={resource?.id} type={resource?.type} />
                      </Grid>
                    </>
                  ) : (
                    <Typography variant="subtitle1" color="error">
                      Failed to load resource details.
                    </Typography>
                  )}
                </>
              ) : (
                // If no element is selected, render a placeholder or message
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Typography variant="h5" gutterBottom>
                        Welcome to Eunoia Asset Explorer!
                      </Typography>
                      <Typography variant="subtitle1" paragraph>
                        Please select an element from the left panel to view its details.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ContentPage;
