import React, { useState } from 'react';
import { Box, Typography, TextField, Grid, Container } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import useRequestAuth from '../../hooks/useRequestAuth'; // Assuming this contains the OTP verification logic
import logo from '../../static/images/logo.png'; // Adjust the path as needed

export default function TotpVerify() {
  const { verifyOtp, loading } = useRequestAuth(); // Assuming verifyOtp is a function in your hook
  const [otpToken, setOtpToken] = useState('');
  const [verificationStatus, setVerificationStatus] = useState(null);
  const navigate = useNavigate();

  const handleVerify = async () => {
    try {
      await verifyOtp(otpToken); // Assuming this function verifies the OTP
      setVerificationStatus('success');
      navigate('/'); // Redirect to the homepage or dashboard upon success
    } catch {
      setVerificationStatus('error');
    }
  };

  return (
    <Container component="main" maxWidth="lg">
      <Grid container spacing={2} sx={{ height: '100vh', alignItems: 'center' }}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
        >
          <Box sx={{ textAlign: 'center' }}>
            <img src={logo} alt="Logo" style={{ width: '150px', marginBottom: '20px' }} />
            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
              Eunoia Security Hub
            </Typography>
            <Typography variant="h6" sx={{ mt: 2, color: 'gray' }}>
              Secure your account with Two-Factor Authentication
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                padding: 3,
                boxShadow: 3,
                borderRadius: 2,
                bgcolor: 'background.paper'
              }}
            >
              <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
                Verify TOTP Code
              </Typography>
              <Typography sx={{ mt: 2, mb: 3 }} variant="body2" color="text.secondary" textAlign="center">
                Enter the 6-digit code from your authenticator app to verify and complete the login process.
              </Typography>
              {verificationStatus === 'error' && (
                <Alert severity="error" sx={{ mb: 3 }}>
                  OTP verification failed. Please try again.
                </Alert>
              )}
              <Box component="form" noValidate onSubmit={(e) => e.preventDefault()} sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="otp"
                  label="One-Time Password (OTP)"
                  name="otp"
                  autoFocus
                  value={otpToken}
                  onChange={(e) => setOtpToken(e.target.value)}
                  error={verificationStatus === 'error'}
                />
                <LoadingButton
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={handleVerify}
                  loading={loading}
                  disabled={!otpToken}
                >
                  Verify OTP
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
